<template>
  <div class="page home">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="platform-amount">
          <div class="big-circle"></div>
          <div class="small-circle"></div>
          <img class="images-icon" src="@/assets/images/finance/platform.png"/>
          <div class="title">平台金额</div>
          <div class="amount-money">￥52465874585.50</div>
          <div class="check-detail"><span>查看明细</span></div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="merchant-amount">
          <div class="big-circle"></div>
          <div class="small-circle"></div>
          <img class="images-icon" src="@/assets/images/finance/merchant.png"/>
          <div class="title">比特金额</div>
          <div class="amount-money">￥22155874.05</div>
          <div class="check-detail"><span>查看明细</span></div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="settlement-amount">
          <div class="big-circle"></div>
          <div class="small-circle"></div>
          <img
              class="images-icon"
              src="@/assets/images/finance/settlement.png"
          />
          <div class="title">应结算金额</div>
          <div class="amount-money">￥18297802.45</div>
          <div class="check-detail"><span>查看明细</span></div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="outer-box withdraw-amount">
          <div class="big-circle"></div>
          <div class="medium-circle"></div>
          <div class="small-circle"></div>
          <div class="header">
            <div class="title">提现金额</div>
            <div class="date-select">
              <div class="date-text active"><span>年</span></div>
              <div class="date-text"><span>月</span></div>
              <div class="date-text"><span>日</span></div>
            </div>
          </div>
          <div class="section">
            <div class="content">
              <div class="amount-money">￥682.5</div>
              <div class="tips">此金额为当月已提现金额</div>
              <div class="check"><span>查看提现明细</span></div>
            </div>
          </div>
          <img class="image-icon" src="@/assets/images/finance/withdraw.png"/>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="outer-box commission">
          <div class="header">
            <div class="title">手续费</div>
            <div class="date-select">
              <div class="date-text active"><span>年</span></div>
              <div class="date-text"><span>月</span></div>
              <div class="date-text"><span>日</span></div>
            </div>
          </div>
          <div class="commission-card">
            <img class="image-icon" src="@/assets/images/finance/entry.png"/>
            <div class="title">入账手续费</div>
            <div class="amount-money">￥78900.50</div>
            <div class="check"><span>查看明细</span></div>
          </div>
          <div class="line"></div>
          <div class="commission-card">
            <img class="image-icon" src="@/assets/images/finance/out.png"/>
            <div class="title">提现手续费</div>
            <div class="amount-money">￥58700.50</div>
            <div class="check"><span>查看明细</span></div>
          </div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="outer-box channel">
          <div class="header">
            <div class="title">渠道对账</div>
            <div class="date-select">
              <div class="date-text active"><span>年</span></div>
              <div class="date-text"><span>月</span></div>
              <div class="date-text"><span>日</span></div>
            </div>
          </div>
          <div class="pay-type">
            <div class="title">微信对账</div>
            <div class="amount">
              <img
                  class="image-icon"
                  src="@/assets/images/finance/wechat.png"
              />
              <div class="amount-money">￥68452005.50</div>
            </div>
            <div class="check-details"><span>查看明细</span></div>
          </div>

          <div class="pay-type">
            <div class="title">支付宝对账</div>
            <div class="amount">
              <img
                  class="image-icon"
                  src="@/assets/images/finance/alipay.png"
              />
              <div class="amount-money">￥15874962.50</div>
            </div>
            <div class="check-details"><span>查看明细</span></div>
          </div>

          <div class="pay-type">
            <div class="title">银联对账</div>
            <div class="amount">
              <img
                  class="image-icon"
                  src="@/assets/images/finance/unionPay.png"
              />
              <div class="amount-money">￥6858746.50</div>
            </div>
            <div class="check-details"><span>查看明细</span></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateSelect: [],
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  min-width: 1600px;

  .platform-amount {
    width: 520px;
    height: 337px;
    background: #13c57c;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    overflow: hidden;

    .big-circle {
      position: absolute;
      top: -144px;
      left: -116px;
      width: 290px;
      height: 290px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
    }

    .small-circle {
      position: absolute;
      top: 276px;
      left: 94px;
      width: 162px;
      height: 162px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
    }

    .images-icon {
      width: 180px;
      height: 180px;
      position: absolute;
      top: 78px;
      right: 26px;
    }

    .title {
      font-size: 24px;
      margin-top: 68px;
      margin-left: 30px;
      font-weight: 600;
    }

    .amount-money {
      font-size: 32px;
      margin-top: 28px;
      margin-left: 30px;
    }
  }

  .check-detail {
    width: 143px;
    height: 50px;
    border-radius: 4px 4px 4px 4px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
    margin-left: 30px;

    span {
      font-size: 16px;
    }
  }

  .merchant-amount {
    width: 520px;
    height: 337px;
    background: #0768FD;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    overflow: hidden;

    .big-circle {
      position: absolute;
      top: -144px;
      left: -116px;
      width: 290px;
      height: 290px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
    }

    .small-circle {
      position: absolute;
      top: 276px;
      left: 94px;
      width: 162px;
      height: 162px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
    }

    .images-icon {
      width: 180px;
      height: 180px;
      position: absolute;
      top: 78px;
      right: 26px;
    }

    .title {
      font-size: 24px;
      margin-top: 68px;
      margin-left: 30px;
      font-weight: 600;
    }

    .amount-money {
      font-size: 32px;
      margin-top: 28px;
      margin-left: 30px;
    }
  }

  .settlement-amount {
    width: 520px;
    height: 337px;
    background: #3c67ff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    overflow: hidden;

    .big-circle {
      position: absolute;
      top: -144px;
      left: -116px;
      width: 290px;
      height: 290px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
    }

    .small-circle {
      position: absolute;
      top: 276px;
      left: 94px;
      width: 162px;
      height: 162px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
    }

    .images-icon {
      width: 180px;
      height: 180px;
      position: absolute;
      top: 78px;
      right: 26px;
    }

    .title {
      font-size: 24px;
      margin-top: 68px;
      margin-left: 30px;
      font-weight: 600;
    }

    .amount-money {
      font-size: 32px;
      margin-top: 28px;
      margin-left: 30px;
    }
  }

  .outer-box {
    margin-top: 26px;
    width: 520px;
    height: 629px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      flex-direction: row;
      margin: 22px 28px 0 42px;
      justify-content: space-between;

      .title {
        font-size: 24px;
        color: #1b2559;
        font-weight: 600;
      }

      .date-select {
        width: 132px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #0768FD;
        margin-top: -4px;
        display: flex;
        flex-direction: row;

        .date-text {
          width: 44px;
          font-size: 14px;
          color: #0768FD;
          border-right: 1px solid #0768FD;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .date-text:nth-child(3) {
          border: none;
        }

        .active {
          background: #0768FD;
          color: #ffffff;
        }
      }
    }
  }

  .withdraw-amount {
    background: #f9faff;
    position: relative;
    overflow: hidden;

    .big-circle {
      width: 244px;
      height: 244px;
      background: #5093ff;
      border-radius: 50%;
      opacity: 0.1;
      position: absolute;
      top: -28px;
      left: -90px;
    }

    .medium-circle {
      width: 165px;
      height: 165px;
      background: #5093ff;
      opacity: 0.2;
      border-radius: 50%;
      position: absolute;
      top: 332px;
      left: 362px;
    }

    .small-circle {
      width: 90px;
      height: 90px;
      background: #5093ff;
      opacity: 0.1;
      border-radius: 50%;
      position: absolute;
      left: 32px;
      bottom: 36px;
    }

    .section {
      width: 358px;
      height: 435px;
      background: #ffffff;
      border-radius: 20px 20px 20px 20px;
      margin: 80px auto 0;
      z-index: 1;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 184px;

        .amount-money {
          font-size: 34px;
          font-weight: 550;
          color: #1b2559;
        }

        .tips {
          font-size: 14px;
          color: #8f9bba;
          margin-top: 12px;
        }

        .check {
          width: 218px;
          height: 46px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d2d2d2;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 80px;

          span {
            font-size: 14px;
            color: #000000;
          }
        }
      }
    }

    .image-icon {
      width: 254px;
      height: 224px;
      position: absolute;
      top: 52px;
      left: 132px;
      z-index: 99;
    }
  }

  .commission {
    .line {
      width: 422px;
      height: 0;
      opacity: 1;
      border: 1px dashed #c1c1c1;
      margin: 26px auto 0;
    }

    .commission-card {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      align-items: center;
    }

    .image-icon {
      width: 74px;
      height: 74px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #1b2559;
      margin-top: 14px;
    }

    .amount-money {
      font-size: 30px;
      color: #000000;
      font-weight: 550;
      margin-top: 28px;
    }

    .check {
      width: 120px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d2d2d2;
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 14px;
        color: #000000;
      }
    }
  }

  .channel {
    .pay-type {
      display: flex;
      flex-direction: column;
      margin-left: 44px;
      margin-top: 74px;
      position: relative;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #1b2559;
      }

      .amount {
        display: flex;
        flex-direction: row;
        margin-top: 32px;

        .image-icon {
          width: 44px;
          height: 44px;
        }

        .amount-money {
          font-size: 34px;
          font-weight: 600;
          color: #000000;
          margin-left: 14px;
          margin-top: 6px;
        }
      }

      .check-details {
        position: absolute;
        top: 10px;
        right: 28px;
        width: 120px;
        height: 34px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d2d2d2;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }
}
</style>
